import { useTranslation } from 'next-i18next';
import { PER_PAGE, VIP_PER_PAGE } from 'constants/';
import { getRequest } from 'modules/request';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { EmptyLayout } from 'components/ant/layouts';
import { Cards } from 'components/ant/ui/Cards';

const Page = (props) => {
  const { listings, vip, page } = props;

  const { t } = useTranslation('common');

  return (
    <EmptyLayout>
      <Cards listings={listings} vip={vip} page={page} />
    </EmptyLayout>
  );
};

export const getStaticProps = async (ctx) => {
  const { page = 1, location = 'worldwide' } = ctx.params;
  const contains = null;
  const vip = await getRequest()
    .get('/listings')
    .query({ contains, location, page, perPage: VIP_PER_PAGE, isVip: true })
    .then((res) => ({
      ...res.body,
      count: parseInt(res.body.count),
    }))
    .catch((err) => {
      return [];
    });

  const listings = await getRequest()
    .get('/listings')
    .query({ contains, location, page, perPage: PER_PAGE, isVip: false })
    .then((res) => ({
      ...res.body,
      count: parseInt(res.body.count),
    }))
    .catch((err) => {
      return { redirect: '/', permanent: false };
    }); // 404 redirect;

  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ['common'])),
      listings,
      vip,
      page,
    },
    revalidate: 1,
  };
};

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export default Page;
